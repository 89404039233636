import styled, { css } from 'styled-components';
import { FeatureCertificationProps } from './FeatureCertification';
import { scrollingHorizontalVisible } from '@/styles/utilities';
import { imageBlurredFadeIn } from '@/components/image/imageLoadedFadeIn.styles';
import { imagePulse } from '@/components/image/imagePulse.styles';

export const Element = styled.div<FeatureCertificationProps>`
  //
`;

export const MainCertificatesGroup = styled.div<FeatureCertificationProps>`
  ${({ theme }) => {
    return css`
      display: flex;
      justify-content: center;
      gap: ${theme.spacing.base};

      margin-bottom: ${theme.spacing.base};

      > div {
        flex: 0 auto;
        width: 100%;
        max-width: 300px;
      }
    `;
  }};
`;

export const MainCertificatesImageWrapper = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      margin: 0 auto ${theme.spacing.base} auto;
      max-width: 300px;
      position: relative;
      height: 10rem;
      > img {
        align-items: center;
        object-fit: contain;
        // Blur effect
        ${imageBlurredFadeIn};
        &.img-loaded {
          opacity: 1 !important;
        }
      }
      // Pulse effect
      ${imagePulse};
    `;
  }};
`;

export const CertificationBadges = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 2rem;

      overflow-x: scroll;
      ${scrollingHorizontalVisible};
    `;
  }};
`;
